@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add this to your CSS file or inside a <style> tag */
.gradient-overlay {
  position: absolute;
  inset: 0;
  z-index: -10;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.9;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.neural-network-background {
  position: relative;
  overflow: hidden;
  background-color: #1a1a2e;
}

.neural-network-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.1), transparent 70%) 10% 10%,
              radial-gradient(circle at 80% 30%, rgba(255, 255, 255, 0.1), transparent 70%) 60% 20%,
              radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.1), transparent 70%) 30% 60%,
              radial-gradient(circle at 70% 80%, rgba(255, 255, 255, 0.1), transparent 70%) 70% 70%;
  background-size: 200px 200px, 300px 300px, 250px 250px, 300px 300px;
  opacity: 0.3;
}

.neural-network-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(45deg, transparent, transparent 40px, rgba(255, 255, 255, 0.05) 41px, rgba(255, 255, 255, 0.05) 42px),
              repeating-linear-gradient(-45deg, transparent, transparent 40px, rgba(255, 255, 255, 0.05) 41px, rgba(255, 255, 255, 0.05) 42px);
  opacity: 0.1;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d; /* Darker gray for the track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #4f46e5; /* Indigo color for the thumb */
  border-radius: 10px;
  border: 2px solid #2d2d2d; /* Gives a little padding inside the track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b3aa8; /* Slightly darker indigo on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #2c288c; /* Even darker indigo when active */
}




/* Override React Quill's default editor styles */
.custom-quill .ql-container {
  background-color: #1e1e2e; /* Slightly lighter dark background */
  border-color: transparent !important;
}

/* Adjust the toolbar */
.custom-quill .ql-toolbar {
  border: none !important;
  margin-bottom: 0.5rem; /* Space between toolbar and editor */
  height: 0px;
  margin-bottom: 22px;
}

/* Toolbar buttons */
.custom-quill .ql-toolbar button {
  color: #e0e0e0; /* Lighter icons/buttons */
}

/* Toolbar buttons on hover */
.custom-quill .ql-toolbar button:hover {
  color: #a3a3ff; /* Subtle color change on hover */
}

/* Scrollbar styling */
.custom-quill .ql-container.ql-snow {
  max-height: 100%; /* Ensure the editor does not overflow */
  overflow-y: auto; /* Allow scrolling */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-quill .ql-container::-webkit-scrollbar {
  display: none; /* Thin scrollbar for WebKit browsers */
}

.custom-quill .ql-container::-webkit-scrollbar-thumb {
  background-color: #4e4e63; /* Darker scrollbar thumb to blend with background */
}

/* Adjust the padding of the Send button to better fit the design */
.text-input-item .ql-editor {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Focus state for the editor */
.custom-quill .ql-container.ql-snow.ql-focused {
  outline: none;
}

/* Ensure the text is styled correctly inside the editor */
.custom-quill .ql-editor p {
  color: inherit; /* Inherit color from the editor container */
  margin: 0; /* Remove default margins */
}

/* Fixing the borders for the whole React Quill container */
.custom-quill {
  overflow: hidden; /* Prevents content from overflowing the container */
}
